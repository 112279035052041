<template>
  <v-col :cols="12" class="pa-2">
    <div class="grey--text text-right">
      {{ label }}
      <span class="error--text" v-if="$attrs.required">*</span>
    </div>
    <v-text-field
      :background-color="$attrs.disabled ? 'grey' : 'grey lighten-2'"
      solo
      hide-details="auto"
      flat
      class="rounded-lg"
      :suffix="suffix"
      v-model="inputVal"
      v-bind="$attrs"
      label=""
    />
  </v-col>
</template>

<script>
import { persianNumToEnglish } from "../constants/GlobalConstants";

export default {
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    suffix: {
      type: String,
      default: "تومان",
    },
  },
  methods: {
    filterNumber: (t) => {
      if (!t) return "";
      if (typeof t != "string") t += "";
      if (t.includes(",")) t = t.split(",").join("");
      if (!/^\d+$/.test(t)) {
        return t;
      }
      return "" + Intl.NumberFormat().format(+t);
    },
  },
  computed: {
    inputVal: {
      get() {
        return typeof this.value == "string"
          ? this.filterNumber(this.value?.trim())
          : this.filterNumber(this.value);
      },
      set(val) {
        this.$emit(
          "input",
          persianNumToEnglish(
            val
              ?.trim()
              ?.split(",")
              .join("")
          )
        );
      },
    },
  },
};
</script>

<style></style>
